<template>
    <div class="input-group">
      <input
        :type="passwordFieldType"
        class="form-control"
        v-model="internalValue"
        :id="id"
        :placeholder="placeholder"
        :class="{ 'is-invalid': validationName?.$error }"
      />
      <div class="input-group-append">
        <div class="input-group-text">
          <span class="mdi font-size-18 pointer" :class="passwordIconClass" :title="passwordIconTitle" v-b-tooltip.hover @click="togglePasswordVisibility"></span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PasswordField',
    props: {
      value: {
        type: String
      },
      validationName: Object,
      id: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: 'Enter your password'
      }
    },
    data() {
      return {
        passwordFieldType: 'password'
      };
    },
    computed: {
      internalValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      passwordIconClass() {
        return this.passwordFieldType === 'password' ? 'mdi-eye' : 'mdi-eye-off';
      },
      passwordIconTitle() {
        return this.passwordFieldType === 'password' ? 'Mostrar' : 'Ocultar';
      }
    },
    methods: { 
      togglePasswordVisibility() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      }
    }
  };
  </script>
  