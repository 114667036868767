<script>
import { required/*, url */} from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import PasswordField from "@/components/forms/password-field.vue";

const initialState = () => {
    return {
        submitted: false,
        store: {
            name: '',
            woocommerce_store_url: '',
            woocommerce_key: '',
            woocommerce_secret: '',
        }
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        PasswordField
    },
    validations: {
        store: {
            name: { required },
            woocommerce_store_url: { /*url*/ },
            woocommerce_key: {  },
            woocommerce_secret: {  },
        }
    },
    methods: {
        async shown() {
            if (this.id) {
                const response = await api.get({
                    url: `stores/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                this.store = response?.data?.data;
            }
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const data = this.store;

            await api.save({
                url: 'stores',
                id: this.store?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        }
    }
};
</script>

<template>
    <Modal :title="'Formulario de tienda'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal">
        <div class="card h-100">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Nombre</label>
                    <input id="name" v-model="store.name" type="text" class="form-control" placeholder="Ingresar nombre"
                        :class="{ 'is-invalid': $v.store.name.$error && submitted }" />
                    <div v-if="!$v.store.name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
                </div>
                <div class="form-group">
                    <label for="woocommerce_store_url">Url</label>
                    <input id="woocommerce_store_url" v-model="store.woocommerce_store_url" type="text" class="form-control" placeholder="Ingresar url de la tienda"
                        :class="{ 'is-invalid': $v.store.woocommerce_store_url.$error && submitted }" />
                    <div v-if="!$v.store.woocommerce_store_url.url && submitted" class="invalid-feedback">Url es inválida</div>
                </div>
                <div class="form-group">
                    <label for="woocommerce_key">Clave del cliente</label>
                    <PasswordField v-model="store.woocommerce_key" id="woocommerce_key" :validationName="$v.store.woocommerce_key" placeholder="Ingresar clave del cliente"></PasswordField>                    
                </div>
                <div class="form-group">
                    <label for="woocommerce_secret">Clave secreta de cliente</label>
                    <PasswordField v-model="store.woocommerce_secret" id="woocommerce_secret" :validationName="$v.store.woocommerce_secret" placeholder="Ingresar clave secreta de cliente"></PasswordField>                    
                </div>
            </div>
        </div>
    </Modal>
</template>