<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import StoreForm from "./forms/store-form.vue";
import CustomTable from "../../../components/tables/custom-table.vue";
import AddButton from "../../../components/buttons/add-button.vue";

/**
 * Stores Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AddButton,
    StoreForm,
    CustomTable
  },
  data() {
    return {
      modalId:'store-form-modal',
      pageHeader:{
        title: "Tiendas",
        breadcrumbs: [
          {
            text: "Tiendas",
            href: "/stores",
            active: true
          }
        ],
      },
      table:{
        url:'/stores',
        fields:[
          { key: "id", label: "Cod", sortable: false },
          { key: "name", label: "Nombre", sortable: false },
          { key: "woocommerce_store_url", label: "Url", sortable: false },
        ],
        params:{with_trashed:1}
      },
      selectedItemId:null      
    };
  },
  methods: {
    showEditModal(item) {
      this.selectedItemId = item?.id || null;
      this.$bvModal.show(this.modalId);
    },
    onSuccess(){
      this.$refs.storeTable.refresh();
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <StoreForm :modal-id="modalId" :id="selectedItemId" @onSuccess="onSuccess"></StoreForm>
            <CustomTable :url="table.url" :fields="table.fields" @edit="showEditModal" :showSoftDelete="true" :params="table.params" ref="storeTable">
              <template v-slot:header-actions>
                <AddButton @click="showEditModal"></AddButton>
              </template>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>